import * as React from 'react';
import type { SVGProps } from 'react';

const SvgError = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="#DBA10E"
    viewBox="0 0 32 32"
    {...props}
  >
    <path d="M16 2.67a13.34 13.34 0 1 0 .01 26.67A13.34 13.34 0 0 0 16 2.67m0 14.66c-.73 0-1.33-.6-1.33-1.33v-5.33c0-.74.6-1.34 1.33-1.34s1.33.6 1.33 1.34V16c0 .73-.6 1.33-1.33 1.33m1.33 5.34h-2.66V20h2.66z" />
  </svg>
);
export default SvgError;
