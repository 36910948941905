import * as React from 'react';
import type { SVGProps } from 'react';

const SvgLoading = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" {...props}>
    <g fill="none">
      <path
        fill="#D7D7D7"
        fillOpacity={0.2}
        d="M16 2.38a13.63 13.63 0 1 0 0 27.25 13.63 13.63 0 0 0 0-27.25m0 3.25a10.38 10.38 0 1 1 0 20.75 10.38 10.38 0 0 1 0-20.75"
      />
      <path
        fill="#FFF"
        d="M16.45 2.38a13.63 13.63 0 1 1-9.6 23.3 1.63 1.63 0 1 1 2.3-2.31 10.38 10.38 0 1 0 7.3-17.74 1.63 1.63 0 0 1 0-3.26Z"
      />
    </g>
  </svg>
);
export default SvgLoading;
