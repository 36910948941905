import { Box, Text, TextBold } from '@m/components/uikit';
import withStyledSystem from '@m/theme/helpers/withStyledSystem';
import styled, { css } from 'styled-components';

import type { StyledSystemProps } from '@m/theme/helpers/withStyledSystem';

export const MessageBox = styled(Box)<StyledSystemProps>`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 0;
  width: 100%;
  padding-bottom: 8px;

  @media (min-height: 1000px) {
    flex-grow: 1;
  }
  ${withStyledSystem}
`;

const messageCommonStyle = css`
  display: block;
  font-size: 22px;
  line-height: 1.3;
  letter-spacing: 0.01em;
  margin-bottom: 18px;

  @media (min-height: 1000px) {
    font-size: 26px;
    margin-bottom: 32px;
    line-height: 1.2;
  }
`;

export const MessageBold = styled(TextBold)<StyledSystemProps>`
  ${messageCommonStyle}
  ${withStyledSystem}
`;

export const MessageBullet = styled(Text)<StyledSystemProps>`
  ${messageCommonStyle}
  position: relative;
  padding-left: 24px;

  &::before {
    content: '•';
    position: absolute;
    top: 0;
    left: 7px;
    font-weight: 700;
  }

  @media (min-width: 700px) {
    padding-left: 42px;

    &::before {
      content: '•';
      left: 14px;
    }
  }

  ${withStyledSystem}
`;
