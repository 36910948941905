import * as React from 'react';
import type { SVGProps } from 'react';

const SvgParkValidate = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="112" height="112" viewBox="0 0 112 112" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#323232"
        d="M21.23 2h69.54c6.69 0 9.11.7 11.56 2A13.63 13.63 0 0 1 108 9.67c1.3 2.45 2 4.87 2 11.56v69.54c0 6.69-.7 9.11-2 11.56a13.63 13.63 0 0 1-5.67 5.67c-2.45 1.3-4.87 2-11.56 2H21.23c-6.69 0-9.11-.7-11.56-2A13.63 13.63 0 0 1 4 102.33c-1.3-2.45-2-4.87-2-11.56V21.23c0-6.69.7-9.11 2-11.56A13.63 13.63 0 0 1 9.67 4c2.45-1.3 4.87-2 11.56-2"
      />
      <path
        fill="#5F59FF"
        d="M18.97 10h74.06c3.12 0 4.25.32 5.39.93a6.36 6.36 0 0 1 2.65 2.65c.6 1.14.93 2.27.93 5.4v74.05c0 3.12-.32 4.25-.93 5.39a6.36 6.36 0 0 1-2.65 2.65c-1.14.6-2.27.93-5.4.93H18.98c-3.12 0-4.25-.32-5.39-.93a6.36 6.36 0 0 1-2.65-2.65c-.6-1.14-.93-2.27-.93-5.4V18.98c0-3.12.32-4.25.93-5.39a6.36 6.36 0 0 1 2.65-2.65c1.14-.6 2.27-.93 5.4-.93Z"
      />
      <path
        fill="#FDFEFE"
        fillRule="nonzero"
        d="M42 56.08V35.15c.01-2.98 1.07-4.12 4.02-4.05 5.34.13 10.7-.51 16.02.4 7.78 1.32 13.78 7.88 14.9 16.16.98 7.42-3.72 15.51-10.6 18.73-3.6 1.68-7.31 1.79-11.11 1.74-1.69-.03-3.39.06-5.08-.03-1.23-.05-1.67.36-1.64 1.62.07 2.64.04 5.28.02 7.93-.02 2.27-1.44 3.84-3.35 3.78-1.72-.05-3.16-1.73-3.17-3.82-.02-7.16 0-14.35 0-21.53Zm6.54-6.69c0 3.46.04 6.91-.03 10.36-.03 1.35.27 1.96 1.78 1.9 2.84-.1 5.69.01 8.53-.05a12.2 12.2 0 0 0 11.65-12c-.04-6.43-5.3-11.86-11.65-11.98-2.84-.06-5.69.06-8.53-.05-1.5-.06-1.82.52-1.79 1.88.08 3.3.04 6.63.04 9.94"
      />
    </g>
  </svg>
);
export default SvgParkValidate;
