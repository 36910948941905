import { useState } from 'react';

import Countdown from '@m/components/Countdown';
import { Box } from '@m/components/uikit';

import CheckIcon from 'apps/kiosk/assets/svg/check';

import {
  SuccessBorder,
  CheckIconBox,
  DetailHeading,
  DetailMessage,
  DoneButton,
  CountdownMessage,
} from './SuccessScreen.styled';

import type { KioskValidationDetail } from 'apps/kiosk/types';

type SuccessScreenProps = {
  validation: KioskValidationDetail | null;
  onDismiss: () => void;
};

export function SuccessScreen({ validation, onDismiss }: SuccessScreenProps) {
  const [showCountdown, setShowCountdown] = useState(false);

  const successMessage = validation
    ? `${validation.validationTerms} parking applied`
    : 'Validation applied';
  return (
    <Box
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      borderRadius="inherit"
      padding="24px"
    >
      <SuccessBorder />
      <CheckIconBox>
        <CheckIcon />
      </CheckIconBox>
      <DetailHeading>{successMessage}</DetailHeading>
      <DetailMessage>You don&apos;t need to do anything else. Just drive out.</DetailMessage>
      <DoneButton onClick={onDismiss} onAnimationEnd={() => setShowCountdown(true)}>
        Done
      </DoneButton>
      {showCountdown && (
        <Countdown
          duration={5}
          offsetStart={250}
          onComplete={onDismiss}
          renderCountdown={(remaining) => (
            <CountdownMessage>{`This screen will close in ${remaining} seconds.`}</CountdownMessage>
          )}
        />
      )}
    </Box>
  );
}
