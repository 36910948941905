import * as React from 'react';
import type { SVGProps } from 'react';

const SvgDismiss = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    fill="#818C99"
    viewBox="0 0 10 10"
    {...props}
  >
    <path d="M9.74.26a.9.9 0 0 1 0 1.28L6.27 5l3.47 3.46a.9.9 0 0 1 .08 1.18l-.08.1a.9.9 0 0 1-1.28 0L5 6.27 1.54 9.74A.9.9 0 1 1 .26 8.46L3.73 5 .26 1.54A.9.9 0 0 1 .18.36l.08-.1a.9.9 0 0 1 1.28 0L5 3.73 8.46.26a.9.9 0 0 1 1.28 0" />
  </svg>
);
export default SvgDismiss;
