import { absoluteFill } from '@m/styled/mixins';
import withStyledSystem from '@m/theme/helpers/withStyledSystem';
import styled from 'styled-components';

import landingBgImg from 'apps/kiosk/assets/img/kiosk-bg.jpg';

import type { StyledSystemProps } from '@m/theme/helpers/withStyledSystem';

export const PageRoot = styled.div<StyledSystemProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  ${withStyledSystem}
`;

export const PageBg = styled.div`
  ${absoluteFill}
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  background-image: url(${landingBgImg});
  background-position: center;
  background-size: cover;
`;

export const PageContent = styled.div<StyledSystemProps>`
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  padding: 24px;
  padding-top: 48px;
  padding-bottom: 80px;
  flex-shrink: 0;

  & > * {
    max-width: 100%;
  }

  @media (max-height: 600px) {
    & > * {
      margin-top: 180px;
    }
  }

  @media (min-height: 700px) {
    padding-top: 24px;
    padding-bottom: 24px;
    justify-content: center;
  }

  ${withStyledSystem}
`;
