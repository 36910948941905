import * as React from 'react';
import type { SVGProps } from 'react';

const SvgArrowForward = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="56"
    fill="none"
    viewBox="0 0 56 56"
    {...props}
  >
    <path
      fill="#fff"
      d="M11.667 30.333H37.73L26.343 41.72c-.91.91-.91 2.403 0 3.313s2.38.91 3.29 0L45.01 29.657c.91-.91.91-2.38 0-3.29l-15.353-15.4c-.91-.91-2.38-.91-3.29 0s-.91 2.38 0 3.29l11.363 11.41H11.667A2.34 2.34 0 0 0 9.333 28a2.34 2.34 0 0 0 2.334 2.333"
    />
  </svg>
);
export default SvgArrowForward;
