import type { ReactNode } from 'react';

import { PageRoot, PageBg, PageContent } from './PageBox.styled';

import type { StyledSystemProps } from '@m/theme/helpers/withStyledSystem';

interface LandingProps extends StyledSystemProps {
  children?: ReactNode;
}

export function PageBox({ children, ...pageRootProps }: LandingProps) {
  return (
    <PageRoot {...pageRootProps}>
      <PageBg />
      <PageContent>{children}</PageContent>
    </PageRoot>
  );
}
