import { Text } from '@m/components/uikit';
import { normalizeButton } from '@m/styled/mixins';
import withStyledSystem from '@m/theme/helpers/withStyledSystem';
import styled from 'styled-components';

import type { StyledSystemProps } from '@m/theme/helpers/withStyledSystem';

export const ModalBackground = styled.button<StyledSystemProps>`
  ${normalizeButton}
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.35);
  pointer-events: inherit;
  ${withStyledSystem}
`;

export const ModalHeading = styled.h3<StyledSystemProps>`
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.015em;
  margin-top: 0;
  margin-bottom: 8px;
  ${withStyledSystem}
`;

export const ModalHeadingLarge = styled.h3<StyledSystemProps>`
  font-size: 28px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: normal;
  margin-top: 0;
  margin-bottom: 24px;

  @media (min-width: 700px) and (min-height: 700px) {
    font-size: 32px;
  }

  @media (min-height: 1000px) {
    font-size: 36px;
  }
  ${withStyledSystem}
`;
export const MessageText = styled(Text)<StyledSystemProps>`
  display: block;
  font-size: 22px;
  line-height: 1.3;
  letter-spacing: 0.01em;
  margin-bottom: 24px;

  @media (min-width: 700px) and (min-height: 700px) {
    font-size: 28px;
    line-height: 1.42857143;
    margin-bottom: 32px;
  }
  ${withStyledSystem}
`;
export const ModalButton = styled.button<StyledSystemProps>`
  ${normalizeButton}
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  width: 100%;
  height: 60px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.035em;
  text-rendering: optimizeLegibility;
  line-height: 49px;

  &:active {
    opacity: 0.75;
  }

  @media (min-width: 700px) and (min-height: 700px) {
    font-size: 22px;
    height: 80px;
  }
  ${withStyledSystem}
`;

export const CloseButtonContent = styled.div<StyledSystemProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background-color: rgba(129, 140, 153, 0.12);
  border-radius: 50%;
  ${withStyledSystem}
`;

export const CloseButtonContentLarge = styled.div<StyledSystemProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  width: 40px;
  height: 40px;
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 1);
  border-radius: 50%;
  ${withStyledSystem}
`;

export const CloseButton = styled.button<{ hide?: boolean } & StyledSystemProps>`
  ${normalizeButton}
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  position: absolute;
  top: 0;
  right: 4px;
  z-index: 10;
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  transition: opacity 200ms linear 0ms;

  &:active ${CloseButtonContent} {
    background-color: rgba(129, 140, 153, 0.4);
  }

  &:active ${CloseButtonContentLarge} {
    background-color: #ededed;
    color: rgba(0, 0, 0, 0.7);
  }

  ${withStyledSystem}
`;
