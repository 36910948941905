import InfoIcon from 'apps/kiosk/assets/svg/info';
import { AlertModal } from 'apps/kiosk/components/Modals/AlertModal';
import {
  ModalHeadingLarge,
  MessageText,
  ModalButton,
} from 'apps/kiosk/components/Modals/Modals.styled';

type AlreadyValidatedProps = {
  show?: boolean;
  onCancel?: () => void;
};

export function AlreadyValidated({ show, onCancel }: AlreadyValidatedProps) {
  return (
    <AlertModal show={show} onCancel={onCancel} icon={<InfoIcon />}>
      <ModalHeadingLarge>Looks like you already validated at this location.</ModalHeadingLarge>
      <MessageText>You may only validate once per location per visit.</MessageText>
      <MessageText opacity={0.55} fontWeight="regular">
        If you have any questions, please see the parking attendant at the exit gate.
      </MessageText>
      <ModalButton onClick={onCancel} backgroundColor="blue6" color="grey1" marginTop="16px">
        Got it
      </ModalButton>
    </AlertModal>
  );
}
