import * as React from 'react';
import type { SVGProps } from 'react';

const SvgTimer = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
    <path
      fill="#5F59FF"
      d="M10.34 1H7.67a.67.67 0 0 0 0 1.33h2.67a.67.67 0 0 0 0-1.33M9 9.67c.37 0 .67-.3.67-.67V6.33a.67.67 0 0 0-1.33 0V9c0 .37.3.67.66.67m4.69-4.41.5-.5a.66.66 0 0 0 0-.93v-.01a.66.66 0 0 0-.94 0l-.5.5A5.97 5.97 0 0 0 9 3a6 6 0 1 0 4.69 2.26M9 13.66a4.66 4.66 0 1 1 0-9.32 4.66 4.66 0 0 1 0 9.33Z"
    />
  </svg>
);
export default SvgTimer;
