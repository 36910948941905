import * as React from 'react';
import type { SVGProps } from 'react';

const SvgCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="24"
    fill="#00CB96"
    viewBox="0 0 26 24"
    {...props}
  >
    <path d="M21.67 2.65 8.5 15.66 4.3 11.5a2.24 2.24 0 0 0-3.16 0 2.19 2.19 0 0 0 0 3.12l5.8 5.73c.87.87 2.28.87 3.15 0L24.85 5.77a2.2 2.2 0 0 0 0-3.12 2.27 2.27 0 0 0-3.18 0" />
  </svg>
);
export default SvgCheck;
