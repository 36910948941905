import * as React from 'react';
import type { SVGProps } from 'react';

const SvgAlert = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="82"
    height="83"
    fill="none"
    viewBox="0 0 82 83"
    {...props}
  >
    <path
      stroke="#D9142C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6.8}
      d="M40.8 7.27a34 34 0 1 0 0 68 34 34 0 0 0 0-68m0 20.4v13.6m0 13.6h-.04"
    />
  </svg>
);
export default SvgAlert;
