import { pulse, spin } from '@m/styled/keyframes';
import { normalizeButton } from '@m/styled/mixins';
import thm from '@m/theme/theme';
import styled, { css, keyframes } from 'styled-components';

import LoadingIcon from 'apps/kiosk/assets/svg/loading';

export const Form = styled.form`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 32px;
  user-select: none;

  @media (min-height: 600px) {
    margin-top: 40px;
  }

  @media (min-height: 768px) {
    margin-top: 56px;
  }

  @media (min-height: 1000px) {
    margin-top: 72px;
  }
`;

export const PlateValue = styled.div<{ isLoading?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  position: relative;
  width: 100%;
  height: 64px;
  padding-left: 16px;
  box-shadow: 0 0 0 ${({ isLoading }) => (isLoading ? 0.8667 : 1.7333)}px
    ${({ theme, isLoading }) => (isLoading ? 'rgba(0,0,0,0.8)' : theme.colors.blue6)};
  border-radius: 10px;
  font-size: 36px;
  font-weight: 500;
  letter-spacing: 0.08em;
  line-height: 0;
  color: ${({ theme }) => theme.colors.grey7};
  user-select: none;
  transition: box-shadow 200ms linear 0ms;

  @media (min-width: 500px) {
    padding-left: 20px;
  }

  @media (min-width: 640px) and (min-height: 640px) {
    height: 88px;
    font-size: 56px;
    border-radius: 16px;
  }

  @media (min-width: 700px) and (min-height: 768px) {
    height: 110px;
    font-size: 62px;
  }
`;

const errorCursorKeyframes = keyframes`
  0% {
    transform: translate3d(0,0,0) rotate(0deg);
    color: ${thm.colors.blue6};
  }
  25% {
    transform: translate3d(4px,0,0) rotate(2deg);
    color: ${thm.colors.orange4};
  }
  50% {
    transform: translate3d(-3px,0,0) rotate(-0.5deg);
  }
  60% {
    color: ${thm.colors.orange4};
  }
  75% {
    transform: translate3d(1.5px,0,0) rotate(0deg);
  }
  100% {
    color: ${thm.colors.blue6};
    transform: translate3d(0,0,0) rotate(0deg);
  }
`;

const errorCursorAnim = css`
  animation: ${errorCursorKeyframes} 600ms ease-out 0ms 1;
`;

export const InputCursor = styled.div<{ error?: boolean; isLoading?: boolean }>`
  position: relative;
  width: 3px;
  height: 48px;
  transition: opacity 100ms linear 0ms;
  opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};
  color: ${({ theme }) => theme.colors.blue6};
  ${({ error }) => (error ? errorCursorAnim : '')}

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: currentColor;
    border-radius: 1.5px;
    animation: ${pulse} 1200ms ease-in-out 0ms infinite;
    animation-play-state: ${({ isLoading }) => (isLoading ? 'paused' : 'running')};
  }

  @media (min-height: 640px) {
    height: 64px;
  }
`;

export const ErrorMessage = styled.div<{ show: boolean }>`
  display: block;
  text-align: center;
  position: absolute;
  bottom: 100%;
  right: 20px;
  color: ${({ theme }) => theme.colors.orange4};
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 28px;
  opacity: ${({ show }) => (show ? 1 : 0)};
  user-select: none;
  transform: translate3d(0, ${({ show }) => (show ? 0 : 16)}px, 0);
  transition-property: opacity, transform;
  transition-timing-function: linear, ease;
  transition-duration: ${({ show }) => (show ? '300ms,200ms' : '100ms,400ms')};
  transition-delay: ${({ show }) => (show ? '300ms' : '0ms')};
`;

export const HelpLink = styled.a<{ inactive?: boolean }>`
  position: absolute;
  top: 100%;
  left: 0;
  height: 44px;
  padding: 0 17px;
  border-radius: 4px;
  margin-left: -16px;
  margin-top: 6px;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  color: ${({ theme }) => theme.colors.blue6};
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
  user-select: none;
  pointer-events: ${({ inactive }) => (inactive ? 'none' : 'auto')};
  opacity: ${({ inactive }) => (inactive ? 0.5 : 1)};

  &:hover {
    color: ${({ theme }) => theme.colors.blue6};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.blue1};
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
  }

  @media (min-height: 1000px) {
    margin-top: 2vh;
  }
`;

export const LoadingSpinner = styled(LoadingIcon)`
  animation: ${spin} 850ms linear 0ms infinite;
`;

export const LoadingBox = styled.div<{ isLoading?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale3d(
    ${({ isLoading }) => (isLoading ? 1 : 0)},
    ${({ isLoading }) => (isLoading ? 1 : 0)},
    1
  );
  opacity: ${({ isLoading }) => (isLoading ? 1 : 0)};
  transition:
    opacity 150ms linear ${({ isLoading }) => (isLoading ? 150 : 0)}ms,
    transform 200ms ease ${({ isLoading }) => (isLoading ? 150 : 0)}ms;

  & ${LoadingSpinner} {
    animation-play-state: ${({ isLoading }) => (isLoading ? 'running' : 'paused')};
  }
`;

type SubmitButtonProps = {
  show?: boolean;
  isLoading?: boolean;
};

function getArrowTransform({ show, isLoading }: SubmitButtonProps) {
  if (!show) {
    return 'translate3d(-24px, 0, 0)';
  }
  if (isLoading) {
    return 'translate3d(24px, 0, 0)';
  }
  return 'translate3d(0, 0, 0)';
}

function getArrowTransition({ show, isLoading }: SubmitButtonProps) {
  if (!show) {
    return 'opacity 100ms linear 0ms, transform 300ms ease 0ms';
  }
  if (isLoading) {
    return 'opacity 150ms linear 0ms, transform 250ms ease 0ms';
  }
  return 'opacity 150ms linear 150ms, transform 300ms ease 150ms';
}

export const SubmitButton = styled.button<SubmitButtonProps>`
  ${normalizeButton}
  position: absolute;
  top: 5px;
  right: 5px;
  width: 54px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.blue6};
  border-radius: ${({ show }) => (show ? '6px' : '50%')};
  pointer-events: ${({ show }) => (show ? 'auto' : 'none')};
  opacity: ${({ show }) => (show ? 1 : 0)};
  transform: scale3d(${({ show }) => (show ? 1 : 0)}, ${({ show }) => (show ? 1 : 0)}, 1);
  transition-property: opacity, transform, border-radius;
  transition-timing-function: linear, ease;
  transition-duration: ${({ show }) => (show ? '400ms, 300ms, 200ms' : '200ms, 300ms, 200ms')};

  & > .arrow-next {
    width: 40px;
    height: 40px;
    opacity: ${({ show, isLoading }) => (show && !isLoading ? 1 : 0)};
    transform: ${getArrowTransform};
    transition: ${getArrowTransition};
  }

  &:disabled {
    pointer-events: none !important;
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.blue7};

    & > .arrow-next {
      fill-opacity: 0.5;
    }
  }

  @media (min-height: 640px) {
    top: 12px;
    right: 12px;
    width: 64px;
    height: 64px;

    & > .arrow-next {
      width: 56px;
      height: 56px;
    }
  }

  @media (min-height: 768px) {
    width: 86px;
    height: 86px;
  }
`;
SubmitButton.defaultProps = {
  type: 'submit',
};
