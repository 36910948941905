import * as React from 'react';
import type { SVGProps } from 'react';

const SvgInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="82"
    height="83"
    fill="none"
    viewBox="0 0 82 83"
    {...props}
  >
    <path
      stroke="#5F59FF"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6.8}
      d="M40.8 75.26a34 34 0 1 0 0-67.99 34 34 0 0 0 0 68Zm0-20.39v-13.6m0-13.6h.02"
    />
  </svg>
);
export default SvgInfo;
