import Wordmark from '@m/assets/svg/wordmark';
import { Box } from '@m/components/uikit';
import theme from '@m/theme/theme';

import ValidateIcon from 'apps/kiosk/assets/svg/park-validate';
import { PageButton } from 'apps/kiosk/components/Button';

import { Heading, SubHeading, PrimaryMessage, HelpLink } from './LandingScreen.styled';

import type { KioskValidationDetail } from 'apps/kiosk/types';

type LandingProps = {
  validation: KioskValidationDetail | null;
  onClickValidate: () => void;
  onClickUnknownPlate: () => void;
};

export function LandingScreen({ validation, onClickValidate, onClickUnknownPlate }: LandingProps) {
  return (
    <>
      <Box
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        css="user-select: none"
      >
        <Box
          width="auto"
          maxWidth="100%"
          height="auto"
          flex={0}
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <ValidateIcon />
          <Heading>Validate parking.</Heading>
          <SubHeading>
            {validation ? (
              <>
                <span>{validation.validationTerms}</span>
                <span>.</span>
              </>
            ) : (
              <span>&nbsp;</span>
            )}
          </SubHeading>
          <PrimaryMessage>License plate number required for validation.</PrimaryMessage>
          <PageButton
            backgroundColor="blue6"
            color="grey1"
            onClick={onClickValidate}
            alignSelf="center"
          >
            Validate
          </PageButton>
          <HelpLink onClick={onClickUnknownPlate}>I don&apos;t know my license plate</HelpLink>
        </Box>
      </Box>
      <Wordmark
        width={70}
        height={20}
        css={`
          position: absolute;
          bottom: 36px;
          left: 50%;
          margin-left: -35px;
          fill: ${theme.colors.grey7};
          opacity: 0.6;
        `}
      />
    </>
  );
}
